import { createMemo, Match, Show, Suspense, Switch } from 'solid-js';
import { Link, Picture } from '@troon/ui';
import { twJoin } from '@troon/tailwind-preset/merge';
import { gql } from '../../graphql';
import type { FacilityHeaderFragment } from '../../graphql';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	bordered?: boolean;
	facility: FacilityHeaderFragment;
	linked?: boolean;
	showHero?: boolean;
}>;

export function FacilityHeader(props: Props) {
	const address = createMemo(() => {
		const address = props.facility.metadata?.address;
		return address ? [address.street, address.city, [address.state, address.postalCode].join(' ')].join(', ') : null;
	});

	return (
		<header class="flex flex-wrap items-center justify-start gap-4 lg:gap-8 ">
			<Show when={props.showHero}>
				<Switch>
					<Match when={props.facility.metadata?.logo?.url}>
						{(logoUrl) => (
							<div class="order-1 flex aspect-square shrink-0 grow-0 basis-32 rounded border border-neutral bg-white p-4 md:basis-32">
								<Picture
									src={logoUrl()}
									sizes={[[200, 200]]}
									mode="contain"
									class="w-full max-w-max rounded object-cover"
								/>
							</div>
						)}
					</Match>
					<Match when={props.facility.metadata?.hero?.url}>
						{(heroUrl) => (
							<div class="order-1 flex shrink-0 grow-0 basis-16 md:basis-32">
								<Picture
									src={heroUrl()}
									sizes={[[200, 200]]}
									class="aspect-square w-full max-w-max rounded object-cover"
								/>
							</div>
						)}
					</Match>
				</Switch>
			</Show>
			<div class="order-3 flex grow basis-full flex-col gap-2 md:gap-4 lg:order-2 lg:basis-auto">
				<h1>
					<Suspense fallback="…">
						<Switch>
							<Match when={props.linked === false}>
								<span class={twJoin('font-semibold', props.showHero ? 'text-3xl lg:text-4xl' : 'text-3xl md:text-5xl')}>
									{props.facility.name}
								</span>
							</Match>
							<Match when>
								<Link href={`/course/${props.facility.slug}`} class="hover:text-brand-600 hover:underline">
									<span
										class={twJoin(
											'font-semibold',
											props.showHero ? 'text-3xl md:text-3xl lg:text-4xl' : 'text-3xl md:text-5xl',
										)}
									>
										{props.facility.name}
									</span>
								</Link>
							</Match>
						</Switch>
					</Suspense>
				</h1>
				<Suspense>
					<Show when={address()}>
						{(address) => (
							<address class="text-sm not-italic text-neutral-800" aria-label={`Address for ${props.facility.name}`}>
								{address()}
							</address>
						)}
					</Show>
				</Suspense>
			</div>

			{/* TODO: wrapping this in a <Show when> results in a hydration mismatch. figure out why */}
			<div class="order-2 flex grow basis-1/2 flex-row justify-end lg:order-3 lg:grow-0 lg:basis-auto">
				{props.children}
			</div>
		</header>
	);
}

gql(`
fragment FacilityHeader on Facility {
	id
	slug
	name
	metadata {
		hero {
			url
		}
		logo {
			url
		}
		address {
			street
			city
			state
			postalCode
			country
		}
	}
}`);
